function Estatistica(prop){
    return(
        <svg viewBox="0 0 512 512" width={prop.tamanho} height={prop.tamanho} id="Layer_1" enableBackground="new 0 0 512 512" >
        <g style={{fill:"#A7834F"}}>
          <g>
            <path d="M137.8,511.9H69.5c-10,0-18.1-8.2-18.1-18.1V354c0-10,8.2-18.1,18.1-18.1h68.3c10,0,18.1,8.2,18.1,18.1v139.8C156,503.8,147.8,511.9,137.8,511.9z"/>
            <path d="M286.9,511.9h-61.8c-11.7,0-21.4-9.6-21.4-21.4V288.8c0-11.7,9.6-21.4,21.4-21.4h61.8c11.7,0,21.4,9.6,21.4,21.4v201.7C308.3,502.3,298.7,511.9,286.9,511.9z"/>
            <path d="M436.5,511.9h-56.3c-13.3,0-24.1-10.9-24.1-24.1V224.3c0-13.3,10.9-24.1,24.1-24.1h56.3c13.3,0,24.1,10.9,24.1,24.1v263.5C460.6,501.1,449.8,511.9,436.5,511.9z"/>
          </g>
          <path d="M445.1,0c-22,0.9-39.1,19.5-38.2,41.6l-101.6,99.9l-86.6-75.8L60.2,224.7c-20.4,2.6-35.7,20.2-34.9,41.1c0.9,22,19.6,39.1,41.6,38.2s39.1-19.6,38.2-41.6c-0.1-1.8-0.5-3.5-0.8-5.3l119.3-114.5l83.7,73.3L446,79.7c0.8,0,1.6,0.2,2.4,0.1c22-0.9,39.1-19.6,38.2-41.6C485.7,16.2,467.1-0.9,445.1,0z"/>
        </g>
</svg>
);
}

export default Estatistica;