function Question(prop){
    return(
      <svg style={{fill:prop.cor}} height={prop.tamanho}viewBox="0 0 20 20" width={prop.tamanho} xmlns="http://www.w3.org/2000/svg">
        <path d="M8.49279 4.90114C8.13479 4.9711 7.7439 5.20337 7.43474 5.74712C7.29826 5.98718 6.99301 6.07114 6.75296 5.93465C6.5129 5.79817 6.42894 5.49292 6.56543 5.25287C7.00628 4.47749 7.63328 4.0502 8.30099 3.9197C8.9539 3.7921 9.60295 3.95667 10.0932 4.28126C10.5768 4.6015 10.9679 5.12423 10.9804 5.75187C10.9934 6.40602 10.5946 6.99672 9.86123 7.43036C9.36263 7.7252 9.16869 7.92513 9.08626 8.05795C9.01687 8.16975 9.00009 8.27172 9.00009 8.5C9.00009 8.77614 8.77623 9 8.50009 9C8.22394 9 8.00009 8.77614 8.00009 8.5C8.00009 8.22827 8.01399 7.88929 8.2366 7.53061C8.44617 7.19295 8.80555 6.89287 9.35222 6.5696C9.88718 6.25326 9.98416 5.95168 9.98059 5.77173C9.97649 5.56527 9.83866 5.31208 9.54108 5.11505C9.25005 4.92235 8.86559 4.82828 8.49279 4.90114Z"/>
        <path d="M8.75 11.5C9.16421 11.5 9.5 11.1642 9.5 10.75C9.5 10.3358 9.16421 10 8.75 10C8.33579 10 8 10.3358 8 10.75C8 11.1642 8.33579 11.5 8.75 11.5Z"/>
        <path d="M8.50019 1C4.91034 1 2.00019 3.91015 2.00019 7.5C2.00019 8.651 2.29978 9.73335 2.82544 10.6719L2.0297 12.7542C1.73642 13.5216 2.4424 14.2957 3.23352 14.0741L5.7209 13.3774C6.5641 13.7768 7.50671 14 8.50019 14C12.09 14 15.0002 11.0899 15.0002 7.5C15.0002 3.91015 12.09 1 8.50019 1ZM3.00019 7.5C3.00019 4.46243 5.46263 2 8.50019 2C11.5378 2 14.0002 4.46243 14.0002 7.5C14.0002 10.5376 11.5378 13 8.50019 13C7.59806 13 6.74803 12.7832 5.99798 12.3993L5.82377 12.3101L2.96381 13.1111L3.93292 10.5753L3.80463 10.3655C3.29438 9.53135 3.00019 8.55079 3.00019 7.5Z"/>
        <path d="M11.4628 17C9.49315 17 7.72814 16.1239 6.53613 14.7402C7.09918 14.8926 7.68851 14.981 8.29584 14.9973C9.19123 15.629 10.2837 16 11.4628 16C12.3649 16 13.2149 15.7832 13.965 15.3993L14.1392 15.3101L16.9992 16.1111L16.0301 13.5752L16.1583 13.3655C16.6686 12.5313 16.9628 11.5508 16.9628 10.5C16.9628 9.34552 16.6071 8.27414 15.9993 7.38943C15.9905 6.78165 15.9095 6.19137 15.7643 5.6268C17.1125 6.81779 17.9628 8.5596 17.9628 10.5C17.9628 11.651 17.6632 12.7333 17.1375 13.6719L17.9333 15.7542C18.2266 16.5216 17.5206 17.2957 16.7295 17.0741L14.2421 16.3774C13.3989 16.7768 12.4563 17 11.4628 17Z"/>
      </svg>

);
}

export default Question;