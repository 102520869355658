function Paper(prop){
    return(
        <svg xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 48 48"
            width={prop.tamanho}
            height={prop.tamanho}
            enableBackground="new 0 0 48 48">
    <path d="M37,47H11c-2.209,0-4-1.791-4-4V5c0-2.209,1.791-4,4-4h18.973c0.002,0,0.005,0,0.007,0h0.02H30c0.32,0,0.593,0.161,0.776,0.395l9.829,9.829C40.84,11.407,41,11.68,41,12v0.021c0,0.002,0,0.003,0,0.005V43C41,45.209,39.209,47,37,47zM31,4.381V11h6.619L31,4.381zM39,13h-9c-0.553,0-1-0.448-1-1V3H11C9.896,3,9,3.896,9,5v38c0,1.104,0.896,2,2,2h26c1.104,0,2-0.896,2-2V13zM33,39H15c-0.553,0-1-0.447-1-1c0-0.552,0.447-1,1-1h18c0.553,0,1,0.448,1,1C34,38.553,33.553,39,33,39zM33,31H15c-0.553,0-1-0.447-1-1c0-0.552,0.447-1,1-1h18c0.553,0,1,0.448,1,1C34,30.553,33.553,31,33,31zM33,23H15c-0.553,0-1-0.447-1-1c0-0.552,0.447-1,1-1h18c0.553,0,1,0.448,1,1C34,22.553,33.553,23,33,23z"
            fillRule="evenodd"
            style={{fill:"#A7834F"}}
            clipRule="evenodd"/>
    </svg>);
}

export default Paper;