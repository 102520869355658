function Prancheta(prop){
    return(
      <svg width={prop.tamanho} height={prop.tamanho}  viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg">
      <title/>
      <g style={{fill:"#A7834F"}}>
        <path d="M110.13,9.69A6.41,6.41,0,0,0,105.77,8h-32a10.18,10.18,0,0,0-2.48-4.86A9.94,9.94,0,0,0,63.94,0h0a9.9,9.9,0,0,0-9.88,8H22a6,6,0,0,0-6,6V122a6,6,0,0,0,6,6h84a5.94,5.94,0,0,0,6-6V14A5.85,5.85,0,0,0,110.13,9.69ZM55.86,12a2,2,0,0,0,2-2,5.91,5.91,0,0,1,6.08-6h0A5.88,5.88,0,0,1,68.4,5.86,6.19,6.19,0,0,1,70,10a2,2,0,0,0,2,2H84V24H44V12ZM108,122a2,2,0,0,1-2,2H22a2,2,0,0,1-2-2V14a2,2,0,0,1,2-2H40V26a2,2,0,0,0,2,2H86a2,2,0,0,0,2-2V12h17.77a2.38,2.38,0,0,1,1.63.61A1.85,1.85,0,0,1,108,14Z"/>
        <path d="M98,20H94a2,2,0,0,0,0,4h2v88H32V24h2a2,2,0,0,0,0-4H30a2,2,0,0,0-2,2v92a2,2,0,0,0,2,2H98a2,2,0,0,0,2-2V22A2,2,0,0,0,98,20Z"/>
        <path d="M66,48H88a2,2,0,0,0,0-4H66a2,2,0,0,0,0,4Z"/>
        <path d="M66,60H82a2,2,0,0,0,0-4H66a2,2,0,0,0,0,4Z"/>
        <path d="M46,59.8a2,2,0,0,0,1.41.58h.09A2,2,0,0,0,49,59.67L59.53,47a2,2,0,1,0-3.06-2.57l-9.19,11-5.87-5.86a2,2,0,0,0-2.82,2.83Z"/>
        <path d="M88,76H66a2,2,0,0,1,0-4H88a2,2,0,0,1,0,4Z"/>
        <path d="M82,88H66a2,2,0,0,1,0-4H82a2,2,0,0,1,0,4Z"/>
        <path d="M47.41,88.38A2,2,0,0,1,46,87.8l-7.41-7.41a2,2,0,1,1,2.82-2.83l5.87,5.86,9.19-11A2,2,0,1,1,59.53,75L49,87.67a2,2,0,0,1-1.45.71Z"/>
      </g>
      </svg>
    );
}

export default Prancheta;