import { useRef, useState } from 'react';
import style from './components css/atucao.module.css';
import Question from './svg/svg_question';
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";

function Atuacao(prop) {
    const [selectState1, setSelectState1] = useState(false);
    const [selectState2, setSelectState2] = useState(false);
    const [selectState3, setSelectState3] = useState(false);
    const [selectState4, setSelectState4] = useState(false);

    const booleanAnimationRef = useRef([false, false,false,false, false, false]);
    prop.referenciaItens.current = [AnimationItens, booleanAnimationRef];
    
    
    return(
        <div  className={style.atuacao} id="atuacao">
            <div className={style.atuacao_main}>
                <div className={style.atuacao_titulo}>
                    <h2>Atuação na Recuperação Judicial</h2>
                    <p>A atuação do “Proxy Hunter” tem contribuído para adesão de credores à aprovação de planos de recuperação judicial de grandes empresas que ingressaram com esse tipo de processo.</p>
                </div>

                <div className={style.atuacao_content}>
                    <div className={style.atuacao_content_item}>
                        <div className={style.atuacao_item_desc}>
                            <Question tamanho={"2.5rem"} cor={"black"}/>
                            <div className={style.atuacao_item_titulo_container} onClick={()=>{setSelectState1((state) => !state);}}>
                                <p className={style.atuacao_item_titulo}>Qual a função do “Proxy Hunter” no processo de Recuperação Judicial?</p>
                                {ArrowChange(selectState1)}
                            </div>
                        </div>
                        <p style={{display: selectState1?"block":"none"}} className={style.atuacao_item_subtitulo}>O “Proxy Hunter” atua como um canal de contato entre a parte recuperanda (aquela que ingressa com o pedido de Recuperação Judicial) e credores, no sentido de oferecer a estes a possibilidade de aderir aos termos do plano de recuperação por meio de acordo, podendo esses credores a partir disso serem representados na Assembleia Geral de Credores pelo “Proxy Hunter”, com a finalidade de votar o plano conforme orientação deles.</p>
                    </div>
                    <div className={style.atuacao_content_item}>
                        <div className={style.atuacao_item_desc}>
                            <Question tamanho={"2.5rem"} cor={"black"}/>
                            <div className={style.atuacao_item_titulo_container} onClick={()=>{setSelectState2((state) => !state);}}>
                                <p className={style.atuacao_item_titulo}>Os credores são obrigados a se valer da atuação do “Proxy Hunter” numa Recuperação Judicial?</p>
                                {ArrowChange(selectState2)}
                            </div>
                        </div>
                        <p style={{display: selectState2?"block":"none"}} className={style.atuacao_item_subtitulo}>Não. Cada credor tem a liberdade de participar diretamente das assembleias, como também de nomear os procuradores que entender conveniente, de modo que o “Proxy Hunter” é somente mais uma opção de procurador à disposição dos credores, para facilitar e concretizar a participação deles na Assembleia de Credores, em consonância com a orientação deles.</p>
                    </div>
                    <div className={style.atuacao_content_item}>
                        <div className={style.atuacao_item_desc}>
                            <Question tamanho={"2.5rem"} cor={"black"}/>
                            <div className={style.atuacao_item_titulo_container} onClick={()=>{setSelectState3((state) => !state);}}> 
                                <p className={style.atuacao_item_titulo}>Qual a posição da Lei vigente sobre a atuação do “Proxy Hunter” na Recuperação Judicial?</p>
                                {ArrowChange(selectState3)}
                            </div>
                        </div>
                        <p style={{display: selectState3?"block":"none"}} className={style.atuacao_item_subtitulo}>A Lei que regula a recuperação judicial (Lei nº 11.101/2005) não veda a atuação do “Proxy Hunter”, desde que o procurador atue de acordo com as instruções do credor. Diversas empresas em recuperação judicial já se valeram da atividade de um “Proxy Hunter” e assim conseguiram a aprovação do seu plano de Recuperação. Tiveram casos em que a parte recuperanda conseguiu aproximadamente 90% de aprovação do seu plano com a atuação do “Proxy Hunter”.</p>
                    </div>
                    <div className={style.atuacao_content_item}>
                        <div className={style.atuacao_item_desc}>
                            <Question tamanho={"2.5rem"} cor={"black"}/>
                            <div className={style.atuacao_item_titulo_container}  onClick={()=>{setSelectState4((state) => !state);}}> 
                                <p className={style.atuacao_item_titulo}>A atuação do “Proxy Hunter” está limitada à intermediação do contato entre recuperanda e credores, assim como à participação na Assembleia Geral de Credores?</p>
                                {ArrowChange(selectState4)}
                            </div>
                        </div>
                        <p style={{display: selectState4?"block":"none"}} className={style.atuacao_item_subtitulo}>A resposta é negativa. O “Proxy Hunter” pode agregar maior controle e legitimidade ao processo de adesão dos credores ao plano, uma vez que tem condições de gerar relatórios na forma em que for combinado, bem como realizar a gestão de documentos firmados e recebidos dos credores, se estiver no escopo da sua atuação. <br/> <br/> Vale ressaltar que o “Proxy Hunter” ainda pode contribuir não só com a aprovação do plano de recuperação, mas também com o cumprimento desse plano, tendo em vista os relatórios gerados e a gestão de documentos realizada.</p>
                    </div>
                </div>

            </div>
        </div>
    );
}

function ArrowChange(state){
    const arrowFalse = <IoIosArrowDown className={style.arrow} />;
    const arrowTrue = <IoIosArrowUp className={style.arrow}/>;
    return state?arrowTrue: arrowFalse;
}

async function AnimationItens(array){
    const docViewTop = window.scrollY;
    const docViewBottom = docViewTop +  window.innerHeight;

    
    const atuacaoTitulo = document.querySelector(`.${style.atuacao_titulo}`);
    if(!array[0]){
        const titulo = atuacaoTitulo.querySelector("h2");
        array[0] = adicionadorDeClasse(titulo, docViewTop, docViewBottom);
    }

    if(!array[1]){
        const subtitulo = atuacaoTitulo.querySelector("p");
        array[1] = adicionadorDeClasse(subtitulo, docViewTop, docViewBottom);
    }

    const itens = document.querySelectorAll(`.${style.atuacao_content_item}`);
    
    itens.forEach((element,index) => {
        if(!array[index + 2]){
            array[index + 2] = adicionadorDeClasse(element, docViewTop, docViewBottom); 
        }
    });

    return array.every((item)=> item);
}

function adicionadorDeClasse(item, docViewTop, docViewBottom) {
    if(item.offsetTop >= docViewTop && docViewBottom >= (item.clientHeight + item.offsetTop)){
        item.classList.add(`${style.animation_opacity}`);
        return true;
    }

    return false;
    
}

export default Atuacao;