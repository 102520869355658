import style from './components css/cabecalho.module.css';
import logo from '../assets/image/logo.png';
import { useEffect, useRef, useState } from 'react';

function Cabecalho(prop){

    const booleanAnimationRef = useRef([false, false,false,false]);

    prop.referencia.current = AnimationNavegacao;
    prop.referenciaLink.current = AnimationLink;
    prop.referenciaItens.current = [AnimationItens, booleanAnimationRef];
   
    useEffect(()=>{

        const navCell = document.querySelector(`.${style.nav_links_cell}`);
        const linkCell = navCell.querySelector(`.${style.nav_link_item}`);
        const nav = document.querySelector(`.${style.nav_link_item}`);
        nav.classList.add(`${style.visible_span}`);
        linkCell.classList.add(`${style.visible_span}`);
        AnimationItens(booleanAnimationRef.current);

    }, []);

    window.onresize = () =>{

        const elem = document.querySelector(`.${style.cabecalho_navegacao_main}`);
    
        let docViewTop = window.scrollY;
    
        let elemTop = elem.offsetTop;
        let elemBottom = elemTop + (elem.offsetHeight / 1.5);

        const nav = elem.querySelector(`.${style.nav_links}`);
        const logo = elem.querySelector(`.${style.logo}`);

        const client = window;

        if(client.innerWidth > 900){

            if(on){
                ButtonHamburguerAnimation(on, setOn);
            }

            if(docViewTop > elemBottom){
                elem.classList.remove(`${style.background_animation_reverse}`);
                nav.classList.remove(`${style.opacity_animation_reverse}`);
        
                elem.classList.add(`${style.background_animation}`);
                nav.classList.add(`${style.opacity_animation}`);
        
                logo.style.width = "180px";

                
            }else if(nav.classList.contains(`${style.opacity_animation}`)){
        
                elem.classList.remove(`${style.background_animation}`);
                nav.classList.remove(`${style.opacity_animation}`);
                logo.style.width = "200px";
            }
        }else{
            const menu = elem.querySelector(`.${style.button_hamburguer}`);
            const navCell = elem.querySelector(`.${style.nav_links_cell}`);


            if(docViewTop > elemBottom){
                
                elem.classList.remove(`${style.background_animation_reverse}`);
                menu.classList.remove(`${style.opacity_animation_reverse}`);
                navCell.classList.remove(`${style.opacity_animation_reverse}`);
                
                elem.classList.add(`${style.background_animation}`);
                menu.classList.add(`${style.opacity_animation}`);
                navCell.classList.add(`${style.opacity_animation}`);
        
                logo.style.width = "160px";
                
            }else if(menu.classList.contains(`${style.opacity_animation}`)){
                elem.classList.remove(`${style.background_animation}`);
                menu.classList.remove(`${style.opacity_animation}`);
                navCell.classList.remove(`${style.opacity_animation}`);

                logo.style.width = "180px";
            }

        }
    }

    

    const [on, setOn] = useState(false);

    return (
        <header id="home" className={style.cabecalho}>
            <div className={style.cabecalho_main}>
                <div className={style.cabecalho_navegacao_main}>
                    <div className={style.cabecalho_navegacao}>
                        <img className={style.logo} src={logo} alt="logo da empresa" />
                        <nav className={style.nav_links}>
                            <div className={style.nav_link_item}>
                                <a className={`${"notranslate"} ${style.nav_link_item_tag_a}`} href="#home">Home</a>
                                <span className={style.nav_link_item_span}></span>
                            </div>
                            <div className={style.nav_link_item}>
                                <a className={style.nav_link_item_tag_a} href="#oquefazemos">O que fazemos</a>
                                <span className={style.nav_link_item_span}></span>
                            </div>
                            <div className={style.nav_link_item}>
                                <a className={`${"notranslate"} ${style.nav_link_item_tag_a}`} href="#etapas">Etapas</a>
                                <span className={style.nav_link_item_span}></span>
                            </div>
                            <div className={style.nav_link_item}>
                                <a className={style.nav_link_item_tag_a} href="#atuacao">Atuação</a>
                                <span className={style.nav_link_item_span}></span>
                            </div>
                            <div className={style.nav_link_item}>
                                <a className={style.nav_link_item_tag_a} href="#portfolio">Portfólio</a>
                                <span className={style.nav_link_item_span}></span>
                            </div>
                            <div className={style.nav_link_item}>
                                <a className={style.nav_link_item_tag_a} href="#contato">Contato</a>
                                <span className={style.nav_link_item_span}></span>
                            </div>
                        </nav>
                        <div className={style.button_hamburguer} onClick={()=>{
                            ButtonHamburguerAnimation(on, setOn)
                        }}>

                            <div className={style.barra1}></div>
                            <div className={style.barra2}></div>
                            <div className={style.barra3}></div>
                        </div>
                    </div>

                    <nav className={style.nav_links_cell} style={{display: on?"flex":"none"}}>
                        <div className={style.nav_link_item}>
                            <a className={`${"notranslate"} ${style.nav_link_item_tag_a}`} href="#home">Home</a>
                            <span className={style.nav_link_item_span_cell}></span>
                        </div>
                        <div className={style.nav_link_item}>
                            <a className={style.nav_link_item_tag_a} href="#oquefazemos">O que fazemos</a>
                            <span className={style.nav_link_item_span_cell}></span>
                        </div>
                        <div className={style.nav_link_item}>
                            <a className={`${"notranslate"} ${style.nav_link_item_tag_a}`} href="#etapas">Etapas</a>
                            <span className={style.nav_link_item_span_cell}></span>
                        </div>
                        <div className={style.nav_link_item}>
                            <a className={style.nav_link_item_tag_a} href="#atuacao">Atuação</a>
                            <span className={style.nav_link_item_span_cell}></span>
                        </div>
                        <div className={style.nav_link_item}>
                            <a className={style.nav_link_item_tag_a} href="#portfolio">Portfólio</a>
                            <span className={style.nav_link_item_span_cell}></span>
                        </div>
                        <div className={style.nav_link_item}>
                            <a className={style.nav_link_item_tag_a} href="#contato">Contato</a>
                            <span className={style.nav_link_item_span_cell}></span>
                        </div>
                    </nav>
                </div>

                <div className={style.cabecalho_content_main}>
                    <div className={style.cabecalho_content}>
                        <h1 className={style.cabecalho_titulo}>Determinação e perseverança são os pilares de uma negociação de sucesso!</h1>
                        <p className={style.cabecalho_texto}>O sucesso profissional ou o sucesso de um negócio não se mede apenas pela capacidade de 
                            entregar um serviço, mas também pela capacidade de solucionar os problemas de cada cliente!</p>
                        <a className={style.button_container} href="#oquefazemos"><button className={style.button}>Saiba mais</button></a>                          
                    </div>
                </div>
            </div>  
        </header>
    );
}

function AnimationNavegacao(){

    const elem = document.querySelector(`.${style.cabecalho_navegacao_main}`);
    
    let docViewTop = window.scrollY;
  
    let elemTop = elem.offsetTop;
    let elemBottom = elemTop + (elem.offsetHeight / 1.5);

    const array = [docViewTop > elemBottom , elem];
    const nav = elem.querySelector(`.${style.nav_links}`);
    const logo = elem.querySelector(`.${style.logo}`);

    const client = window;

    if(client.innerWidth > 900){
        if(docViewTop > elemBottom){
            elem.classList.remove(`${style.background_animation_reverse}`);
            nav.classList.remove(`${style.opacity_animation_reverse}`);
    
            elem.classList.add(`${style.background_animation}`);
            nav.classList.add(`${style.opacity_animation}`);
    
            logo.style.width = "180px";

              
        }else if(nav.classList.contains(`${style.opacity_animation}`)){
            
            elem.classList.add(`${style.background_animation_reverse}`);
            nav.classList.add(`${style.opacity_animation_reverse}`);
    
            elem.classList.remove(`${style.background_animation}`);
            nav.classList.remove(`${style.opacity_animation}`);
            logo.style.width = "200px";

            setTimeout(()=>{
                elem.classList.remove(`${style.background_animation_reverse}`);
                nav.classList.remove(`${style.opacity_animation_reverse}`);
            }, 300);

        }
    }else{

        const menu = elem.querySelector(`.${style.button_hamburguer}`);
        const navCell = elem.querySelector(`.${style.nav_links_cell}`);


        if(docViewTop > elemBottom){
            elem.classList.remove(`${style.background_animation_reverse}`);
            menu.classList.remove(`${style.opacity_animation_reverse}`);
            navCell.classList.remove(`${style.opacity_animation_reverse}`);
            
    
            elem.classList.add(`${style.background_animation}`);
            menu.classList.add(`${style.opacity_animation}`);
            navCell.classList.add(`${style.opacity_animation}`);
    
            logo.style.width = "160px";
              
        }else if(menu.classList.contains(`${style.opacity_animation}`)){
            elem.classList.add(`${style.background_animation_reverse}`);
            menu.classList.add(`${style.opacity_animation_reverse}`);
            navCell.classList.add(`${style.opacity_animation_reverse}`);
    
    
            elem.classList.remove(`${style.background_animation}`);
            menu.classList.remove(`${style.opacity_animation}`);
            navCell.classList.remove(`${style.opacity_animation}`);

            setTimeout(()=>{
                elem.classList.remove(`${style.background_animation_reverse}`);
                menu.classList.remove(`${style.opacity_animation_reverse}`);
                navCell.classList.remove(`${style.opacity_animation_reverse}`);
            }, 300);

            logo.style.width = "180px";
        }
    }
  
    return array;
}

function ButtonHamburguerAnimation(on, setOn){
    const barras = document.querySelector(`.${style.button_hamburguer}`)?.querySelectorAll("div");

    if(barras != undefined){
    if(!on){
        barras[0].classList.add(style.barra1_class_animation);
        barras[1].classList.add(style.barra2_class_animation);
        barras[2].classList.add(style.barra3_class_animation);


        barras[0].classList.remove(style.barra1_class_animation_reverse);
        barras[1].classList.remove(style.barra2_class_animation_reverse);
        barras[2].classList.remove(style.barra3_class_animation_reverse);           
    }else{
        barras[0].classList.add(style.barra1_class_animation_reverse);
        barras[1].classList.add(style.barra2_class_animation_reverse);
        barras[2].classList.add(style.barra3_class_animation_reverse);

        barras[0].classList.remove(style.barra1_class_animation);
        barras[1].classList.remove(style.barra2_class_animation);
        barras[2].classList.remove(style.barra3_class_animation);

    }
    }
        
    setOn(!on);
        
}

function AnimationLink(){
    const elem = document.querySelector(`.${style.cabecalho_navegacao_main}`);
    
    const home = document.querySelector("#home");
    const fazemos = document.querySelector("#oquefazemos");
    const etapas = document.querySelector("#etapas");
    const atuacao = document.querySelector("#atuacao");
    const portfolio = document.querySelector("#portfolio");
    const contato = document.querySelector("#contato");
    
    const arrayTopView = [];

    arrayTopView.push(home.offsetTop);
    arrayTopView.push(fazemos.offsetTop);
    arrayTopView.push(etapas.offsetTop);
    arrayTopView.push(atuacao.offsetTop);
    arrayTopView.push(portfolio.offsetTop);
    arrayTopView.push(contato.offsetTop);

    const navList = document.querySelector(`.${style.nav_links}`);

    const client = window;

    if(client.innerWidth > 900){

        const docViewTop = window.scrollY + elem.offsetHeight;
        TrocaSpan(docViewTop, navList, arrayTopView)
        
    }else{
        const navList = document.querySelector(`.${style.nav_links_cell}`);
        const logo = document.querySelector(`.${style.logo}`);
        const docViewTop = window.scrollY + logo.clientHeight * 2 + logo.clientHeight / 4;
        TrocaSpan(docViewTop, navList, arrayTopView)

    }

  
}

function TrocaSpan(docViewTop, navList, arrayTopView){
    for (let index = 0; index <= arrayTopView.length; index++) {
        const item = arrayTopView[index];
        
        if(docViewTop >= item){
            continue;
        }else{
            const atual = navList.querySelector(`.${style.visible_span}`);
            const list = navList.querySelectorAll(`.${style.nav_link_item}`);

            if(atual != null){
                atual.classList.remove(`${style.visible_span}`);
            }
            
            list[index - 1].classList.add(`${style.visible_span}`);
            break;
        }
        
    }
}

async function AnimationItens(array){
    const logo = document.querySelector(`.${style.logo}`);
    const titulo = document.querySelector(`.${style.cabecalho_titulo}`);
    const home = document.querySelector("#home");
    const docViewTop = window.scrollY;
    const docViewBottom = docViewTop +  window.innerHeight;

    if(!array[0]){
        logo.classList.add(`${style.animation_horizontal}`);
        array[0] = true;
    }

    
    if(titulo.offsetTop >= docViewTop && docViewBottom >= home.clientHeight){
        const subtitulo = document.querySelector(`.${style.cabecalho_texto}`);
        const botao = document.querySelector(`.${style.button_container}`);

        if(!array[1]){
            await new Promise(resolve => setTimeout(resolve, 200));
            titulo.classList.add(`${style.animation_horizontal}`);
            array[1] = true;
        }

        if(!array[2]){
            await new Promise(resolve => setTimeout(resolve, 200));  
            subtitulo.classList.add(`${style.animation_horizontal}`);
            array[2] = true;
        }

        if(!array[3]){
            await new Promise(resolve => setTimeout(resolve, 200));   
            botao.classList.add(`${style.animation_horizontal}`);
            array[3] = true;
        }    
    }
    return array.every((item)=> item);
}

export default Cabecalho;