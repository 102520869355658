function Circle(){
    return(
        <svg height="100%"  id="Layer_1" style={{enableBackground: "new 0 0 512 512"}} version="1.1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
            <defs>
                <linearGradient id="meuGradiente" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="0%" style={{ stopColor: "#A7834F", stopOpacity: 1 }} />
                    <stop offset="50%" style={{ stopColor: "#F3E9A2", stopOpacity: 1 }} />
                    <stop offset="100%" style={{ stopColor: "#A7834F", stopOpacity: 1 }} />
                </linearGradient>
            </defs>
                            
            <path d="M256,48C141.1,48,48,141.1,48,256s93.1,208,208,208c114.9,0,208-93.1,208-208S370.9,48,256,48z M256,446.7    c-105.1,0-190.7-85.5-190.7-190.7c0-105.1,85.5-190.7,190.7-190.7c105.1,0,190.7,85.5,190.7,190.7    C446.7,361.1,361.1,446.7,256,446.7z" style={{ fill: "url(#meuGradiente)" }}/>
                               
        </svg>
    );
}

export default Circle;