import style from './components css/contato.module.css';
import imagem from '../assets/image/imagem_contanto.jpg';
import { useEffect, useRef, useState } from 'react';

function Contato() {
   
    const [spanVisible, setSpanVisible] = useState(false);
    const animationRef = useRef(true);
    const FormButtonClickRef = useRef(true);

    useEffect(()=>{
        const button = document.querySelector(`.${style.form_button}`);

        const nome = document.querySelector("#nome");
        const email = document.querySelector("#email");
        const telefone = document.querySelector("#telefone");
        const mensagem = document.querySelector("#mensagem");

        nome.addEventListener("blur" ,(event)=>{
            valida(event.target);
        });

        email.addEventListener("blur" ,(event)=>{
            valida(event.target);
        });

        telefone.addEventListener("blur" ,(event)=>{
            valida(event.target);
        });

        mensagem.addEventListener("blur" ,(event)=>{
            valida(event.target);
        });

        
        
        button.addEventListener("click" , (event)=>{
            event.preventDefault();
            
            if(FormButtonClickRef.current){
                FormButtonClickRef.current = false;
                valida(nome);
                valida(email);
                valida(telefone);
                valida(mensagem);
                
                if(nome.validity.valid &&
                    email.validity.valid &&
                    telefone.validity.valid &&
                    mensagem.validity.valid){
                        
                        const url = 'https://parley.com.br/api/apiEnviarEmail.php';
                                
                        const dadosEmail = {
                            nomeDestinatario: nome.value,
                            emailDestinatario: email.value,
                            mensagem: `O Cliente ${nome.value} enviou um email através do Site da Parley, segue as informações:\n\nNome: ${nome.value}\nEmail: ${email.value}\nTelefone: ${telefone.value}\nMensagem: ${mensagem.value}`
                        };
                        mensagemSpanInfinite("Carregando...", setSpanVisible, animationRef, false);
                        fetch(url, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(dadosEmail)
                        }).then(response => {
                            mensagemSpanInfinite("Carregando...", setSpanVisible, animationRef, true);

                            setTimeout(()=>{
                                const form = document.querySelector(`.${style.contato_form}`);


                                if(response.status == 200){
                                    mensagemSpan("Mensagem enviada com sucesso!", setSpanVisible, animationRef);
                                    form.reset();
                                }else if(response.status == 429){
                                    mensagemSpan("Aguarde alguns instantes antes de enviar outra mensagem!", setSpanVisible, animationRef);
                                }
                                else{
                                    mensagemSpan("Houve um problema no envio da mensagem, tente novamente mais tarde, ou entre em contato pelo WhatsApp!", setSpanVisible, animationRef);  
                                }
                                FormButtonClickRef.current = true;
                            }, 1000);
                            
                        }).catch(error => {
                            mensagemSpan("Houve um problema no envio da mensagem, verifique sua internet e tente novamente, ou entre em contato pelo WhatsApp!", setSpanVisible, animationRef);
                            FormButtonClickRef.current = true;
                        });
        
                }else{
                    FormButtonClickRef.current = true;
                }
            }

        });
    }, []);
    return(
        <div className={style.bloco} id="contato">
            
            <div className={style.bloco_main}>

                <div className={style.bloco_content}>
                    <h3>Sem Compromisso</h3>
                    <h2>Entre em contato</h2>
                    <form className={style.contato_form}>
                        <div className={style.input_container}>
                            <input name="nome" className={style.input_container_input} required id="nome" data-tipo="nome" type="text" placeholder="" />
                            <label className={style.input_container_label} htmlFor="nome">Nome</label>
                            <span className={style.input_container_span_error}></span>
                        </div>
                        <div className={style.input_container}>
                            <input name="email" className={style.input_container_input} required id="email" data-tipo="email" type="email" placeholder="" />
                            <label className={style.input_container_label} htmlFor="email">Email</label>
                            <span className={style.input_container_span_error}></span>
                        </div>
                        <div className={style.input_container}>
                            <input name="telefone" className={style.input_container_input} id="telefone" data-tipo="telefone" type="text" placeholder="" />
                            <label className={style.input_container_label} htmlFor="telefone">Telefone</label>
                            <span className={style.input_container_span_error}></span>
                        </div>
                        <div className={style.text_area_container}> 
                            <textarea className={style.text_area}  name="mensagem" required id="mensagem" data-tipo="mensagem" placeholder="" cols={30} rows={10}></textarea>
                            <label className={style.input_container_label}  htmlFor="mensagem">Mensagem</label>
                            <span className={style.input_container_span_error}></span>
                        </div>

                        <div>
                            <button className={style.form_button} type="submit">Enviar</button>
                            <span style={{display: spanVisible?"inline":"none"}} className={style.span_de_confirmacao}> </span> 
                        </div>

                        
                    </form>
                </div>
                <div  className={style.bloco_content_image_div}>
                    <img className={style.bloco_content_image} src={imagem} alt="dois homens morenos apertando a mão em uma mesa de madeira com mais duas mulheres do lado de cada homem."/>
                </div>
            </div>
        </div>
    );
}

function valida(input) {
    const tipoDeInput = input.dataset.tipo;
    
   if(validadores[tipoDeInput]) {
        validadores[tipoDeInput](input)
    }

    if(input.validity.valid) {
        input.parentElement.classList.remove(`${style.input_invalido}`);
        input.parentElement.querySelector(`.${style.input_container_span_error}`).innerHTML = ''
        
    } else {
        input.parentElement.classList.add(`${style.input_invalido}`);
        input.parentElement.querySelector(`.${style.input_container_span_error}`).innerHTML = mostraMensagemDeErro(tipoDeInput, input);
    }

}

const tiposDeErro = [
    'valueMissing',
    'typeMismatch',
    'customError'
]

const validadores = {
	nome:input => validaTamanho(input),
	email:input => validaEstrutura(input),
	assunto:input => validaTamanho(input),
	mensagem:input => validaTamanho(input)	
}


const mensagensDeErro = {
    nome: {
        valueMissing: 'O campo de nome não pode estar vazio.',
        customError:  'O texto deve conter no máximo 50 carateres.'
    },
    email: {
        valueMissing: 'O campo de email não pode estar vazio.',
        typeMismatch: 'O email digitado não é válido.',
        customError:  'O email digitado não é válido.'
    },
    telefone: {
    	valueMissing: 'O campo de telefone não pode estar vazio.',
        customError:  'O texto deve conter no máximo 50 carateres.'
    },
    mensagem: {
    	valueMissing: 'O campo de mensagem não pode estar vazio.',
    }
}

function mostraMensagemDeErro(tipoDeInput, input) {
    let mensagem = ''
    tiposDeErro.forEach(erro => {
        if(input.validity[erro]) {
            mensagem = mensagensDeErro[tipoDeInput][erro]
        }
    })
    
    return mensagem
}

function validaTamanho(input){

	var tipoDeInput = input.dataset.tipo;
	var valor = input.value.length
	var mensagem = ""

	if ((tipoDeInput == "nome" || tipoDeInput == "assunto") && valor > 50){
		mensagem = "O texto deve conter no máximo 50 carateres."	
	} 

	input.setCustomValidity(mensagem)
}

function validaEstrutura(input){

	var mensagem = ""

	if(input.value.indexOf(".") == -1){
		mensagem = "Deve estar em formato de e-mail contendo o caractere especial @ seguido por um domínio ou provedor seguido por um ponto (.) Exemplo: text@texto.com"
	}
	if(input.validity.valueMissing == false && input.validity.typeMismatch == false){
		input.setCustomValidity(mensagem)
	}

}

function mensagemSpan(mensagem, setSpanVisible, animationRef) {
    if(animationRef.current){
        const spanView = document.querySelector(`.${style.span_de_confirmacao}`);
        spanView.innerHTML = mensagem;
        spanView.classList.add(`${style.animation_opacity}`);
        animationRef.current = false;
        setSpanVisible(true);
        setTimeout(()=>{
            spanView.classList.add(`${style.animation_opacity_reverse}`);
        }, 4000);
        setTimeout(()=>{
            setSpanVisible(false);
            spanView.classList.remove(`${style.animation_opacity}`);
            spanView.classList.remove(`${style.animation_opacity_reverse}`);
            spanView.innerHTML = "";
            animationRef.current = true;
            
        }, 5000);
    }
    
}

function mensagemSpanInfinite(mensagem, setSpanVisible, animationRef, turnoff) {

    const spanView = document.querySelector(`.${style.span_de_confirmacao}`);

    if(animationRef.current){
        spanView.innerHTML = mensagem;
        spanView.classList.add(`${style.animation_opacity}`);
        animationRef.current = false;
        setSpanVisible(true);
    }

    if(turnoff){
        
        spanView.classList.add(`${style.animation_opacity_reverse}`);
        
        setTimeout(()=>{
            setSpanVisible(false);
            spanView.classList.remove(`${style.animation_opacity}`);
            spanView.classList.remove(`${style.animation_opacity_reverse}`);
            spanView.innerHTML = "";
            animationRef.current = true;
            
        }, 1000);
    }
    
}

export default Contato;