import style from './App.module.css';
import Cabecalho from './components/cabecalho';
import OQueFazemos from './components/o_que_fazemos';
import Etapas from './components/etapas';
import Atuacao from './components/atuacao';
import Portifolio from './components/portifolio';
import Footer from './components/footer';
import { useRef } from 'react';
import { FaWhatsapp } from "react-icons/fa";
import Contato from './components/contato';

  

 function App()  {
  const NavRef = useRef();
    const LinkRef = useRef();
    const CabecalhoItensRef = useRef();
    const FazemosItensRef = useRef();
    const EtapasItensRef = useRef();
    const AtuacaoItensRef = useRef();
    const PortifolioItensRef = useRef();
    const FooterItensRef = useRef();
    const ContinuaRef = useRef([true, true, true, true, true, true]);

    document.onscroll =  async ()=>{
      NavRef.current();
      LinkRef.current();
      if(ContinuaRef.current[0]){
        const resultado = await CabecalhoItensRef.current[0](CabecalhoItensRef.current[1].current);
        ContinuaRef.current[0] = !resultado;
      }

      if(ContinuaRef.current[1]){
        const resultado = await FazemosItensRef.current[0](FazemosItensRef.current[1].current);
        ContinuaRef.current[1] = !resultado;
      }

      if(ContinuaRef.current[2]){
        const resultado = await EtapasItensRef.current[0](EtapasItensRef.current[1].current);
        ContinuaRef.current[2] = !resultado;
      }

      if(ContinuaRef.current[3]){
        const resultado = await AtuacaoItensRef.current[0](AtuacaoItensRef.current[1].current);
        ContinuaRef.current[3] = !resultado;
      }

      if(ContinuaRef.current[4]){
        const resultado = await PortifolioItensRef.current[0](PortifolioItensRef.current[1].current);
        ContinuaRef.current[4] = !resultado;
      }

      if(ContinuaRef.current[5]){
        const resultado = await FooterItensRef.current[0](FooterItensRef.current[1].current);
        ContinuaRef.current[5] = !resultado;
      }
    };


    return (
      <div>
        <Cabecalho referencia={NavRef} referenciaItens={CabecalhoItensRef} referenciaLink={LinkRef}/>
      <main>
        <div className={style.logo}>
          <a target="_blank" href="https://wa.me/5511961183683"><FaWhatsapp/></a>
        </div>
        <OQueFazemos referenciaItens={FazemosItensRef}/>
        <Etapas referenciaItens={EtapasItensRef}/>
        <Atuacao referenciaItens={AtuacaoItensRef}/>
        <Portifolio referenciaItens={PortifolioItensRef} />
        <Contato/>
      </main>
        <Footer referenciaItens={FooterItensRef}/>
      </div>
    );
}

export default App;
