import style from './components css/o_que_fazemos.module.css';
import imagem from '../assets/image/imagem_o_que_fazemos.jpg';
import { useRef } from 'react';

function OQueFazemos(prop) {
    const booleanAnimationRef = useRef([false, false,false,false, false, false]);
    prop.referenciaItens.current = [AnimationItens, booleanAnimationRef];
    return(
        <div className={style.bloco} id="oquefazemos">
            <div className={style.bloco_main}>
                <div className={style.bloco_content}>
                    <h3>NEGOCIAR E INTERMEDIAR</h3>
                    <h2>O que Fazemos</h2>
                    <div>
                        <p className={style.bloco_content_p1}>A PARLEY presta assessoria especializada em intermediação das negociações de créditos extraconcursais ou, em especial, créditos vinculados ao procedimento recuperacional e/ou  falimentar.</p>
                        <p className={style.bloco_content_p2}>Nosso trabalho consiste em intermediar e iniciar as tratativas com todos os credores, e esclarecer todos os pontos do Plano de Recuperação Judicial que será apresentado em assembleia, assim como, as características do procedimento do processo de Recuperação Judicial, prestar esclarecimentos sobre, o valor do crédito, forma de pagamento, andamento do processo de Recuperação Judicial e da Assembleia Geral de Credores. </p>
                        <p className={style.bloco_content_p3}>Com esse trabalho, conseguimos uma prévia das abstenções, dos votos contrários e os favoráveis, para melhor mapeamento do ambiente assemblear.</p>
                    </div>
                </div>
                <div  className={style.bloco_content_image_div}>
                    <img className={style.bloco_content_image} src={imagem} alt="dois homens morenos apertando a mão em uma mesa de madeira com mais duas mulheres do lado de cada homem."/>
                </div>
            </div>
        </div>
    );
}

async function AnimationItens(array){
    const imagem = document.querySelector(`.${style.bloco_content_image}`);
    const docViewTop = window.scrollY;
    const docViewBottom = docViewTop +  window.innerHeight;

    

    if(!array[0] && docViewTop <= imagem.offsetTop && docViewBottom >= (imagem.clientHeight  / 6  + imagem.offsetTop)){
        imagem.classList.add(`${style.animation_horizontal}`);
        array[0] = true;
    }else if(!array[0] && docViewTop <= imagem.offsetTop + imagem.clientHeight * 0.7 && docViewBottom >= imagem.clientHeight + imagem.offsetTop){
        imagem.classList.add(`${style.animation_horizontal}`);
        array[0] = true;
    }

    const blocoContent = document.querySelector(`.${style.bloco_content}`);

    if(!array[1]){
        const subtitulo = blocoContent.querySelector("h3");
        array[1] = adicionadorDeClasse(subtitulo, docViewTop, docViewBottom);
    }

    if(!array[2]){
        const titulo = blocoContent.querySelector("h2");
        array[2] = adicionadorDeClasse(titulo, docViewTop, docViewBottom);
    }

    if(!array[3]){
        const p1 = document.querySelector(`.${style.bloco_content_p1}`);
        array[3] = adicionadorDeClasse(p1, docViewTop, docViewBottom);
    }


    if(!array[4]){
        const p2 = document.querySelector(`.${style.bloco_content_p2}`);
        array[4] = adicionadorDeClasse(p2, docViewTop, docViewBottom);
    }

    if(!array[5]){
        const p3 = document.querySelector(`.${style.bloco_content_p3}`);       
        array[5] = adicionadorDeClasse(p3, docViewTop, docViewBottom);
    }


    return array.every((item)=> item);
}

function adicionadorDeClasse(item, docViewTop, docViewBottom) {
    if(item.offsetTop >= docViewTop && docViewBottom >= (item.clientHeight + item.offsetTop)){
        item.classList.add(`${style.animation_horizontal}`);
        return true;
    }

    return false;
    
}

export default OQueFazemos;