import style from './components css/portifolio.module.css';
import imagem from '../assets/image/foto_portifolio.jpg';
import { useRef } from 'react';

function Portifolio(prop) {

    const booleanAnimationRef = useRef([false, false,false,false, false, false,false, false, false]);
    prop.referenciaItens.current = [AnimationItens, booleanAnimationRef];
    return(
        <div id="portfolio">
            <div className={style.bloco_main}>
                <div className={style.bloco_main_inner}>
                    <div  className={style.bloco_content_image_div}>
                        <img className={style.image}  src={imagem} alt="Foto do advogado Sidney Nascimento."/>
                        <p className={style.email}>sidneynascimento@parley.com.br</p>
                    </div>
                    <div className={style.bloco_content}>
                        <h3>Advogado</h3>
                        <h2>Dr. Sidney Nascimento</h2>
                        <div className={style.bloco_content_texts}>
                            <p className={style.bloco_content_p1}>Graduado em Direito pela Universidade Estácio de Sá e em estudos complementares em Direito Empresarial pela Faculdade Integrada Campos Salles. </p>
                            <p className={style.bloco_content_p2}>Advogado, com vivência e imersão total na advocacia, cursou Recuperação Judicial de Empresas pelo EBRADI, cujo programa é totalmente voltado a complexidade do procedimento recuperacional. Atuante em âmbito nacional em direito privado, com ênfase em Recuperação Judicial e negociação de ativos e passivos de todo e qualquer natureza.</p>
                            <p className={style.bloco_content_p3}>Atuou por mais de 8 anos em diversos escritórios de negociação/cobrança do Brasil, nos últimos anos atuou com exclusividade na área de recuperação judicial, adquirindo conhecimento aprofundado de suas bases jurídicas.</p>
                            <p className={style.bloco_content_p4}>Sócio Fundador da Parley Assessoria. Hoje lidera uma equipe com diversos profissionais especializados em intermediação e negociação no âmbito da recuperação judicial, assim como, colaboradores especializados em cobrança nas mais variadas áreas do mercado, atendendo pessoas jurídicas e físicas em todo o país.</p>
                            <p className={style.bloco_content_p5}>As políticas e práticas da Parley Assessoria, sob a orientação do Dr. Sidney Nascimento, nos permitem oferecer um serviço que não só atende, mas muitas vezes supera as expectativas dos clientes. Nosso enfoque único contribui para a nossa reputação como uma assessoria dinâmica, moderna e altamente competente, cujo slogan divulgado a nossos clientes é <span>SEU SUCESSO É NOSSO SUCESSO!</span></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


async function AnimationItens(array){
    
    const imagem = document.querySelector(`.${style.image}`);
    const docViewTop = window.scrollY;
    const docViewBottom = docViewTop +  window.innerHeight;

    if(!array[0] && docViewTop <= imagem.offsetTop && docViewBottom >= (imagem.clientHeight  * 0.1  + imagem.offsetTop)){
        imagem.classList.add(`${style.animation_vertical}`);
        array[0] = true;
    }else if(!array[0] && docViewTop <= imagem.offsetTop + imagem.clientHeight * 0.7 && docViewBottom >= imagem.clientHeight + imagem.offsetTop){
        imagem.classList.add(`${style.animation_vertical}`);
        array[0] = true;
    }

    const blocoContent = document.querySelector(`.${style.bloco_content}`);

    if(!array[1]){
        const subtitulo = blocoContent.querySelector("h3");
        array[1] = adicionadorDeClasse(subtitulo, docViewTop, docViewBottom);
    }

    if(!array[2]){
        const titulo = blocoContent.querySelector("h2");
        array[2] = adicionadorDeClasse(titulo, docViewTop, docViewBottom);
    }

    if(!array[3]){
        const p1 = document.querySelector(`.${style.bloco_content_p1}`);
        array[3] = adicionadorDeClasse(p1, docViewTop, docViewBottom);
    }


    if(!array[4]){
        const p2 = document.querySelector(`.${style.bloco_content_p2}`);
        array[4] = adicionadorDeClasse(p2, docViewTop, docViewBottom);
    }

    if(!array[5]){
        const p3 = document.querySelector(`.${style.bloco_content_p3}`);       
        array[5] = adicionadorDeClasse(p3, docViewTop, docViewBottom);
    }

    if(!array[6]){
        const p4 = document.querySelector(`.${style.bloco_content_p4}`);       
        array[6] = adicionadorDeClasse(p4, docViewTop, docViewBottom);
    }

    if(!array[7]){
        const p5 = document.querySelector(`.${style.bloco_content_p5}`);       
        array[7] = adicionadorDeClasse(p5, docViewTop, docViewBottom);
    
    }
    if(!array[8]){
        const email = document.querySelector(`.${style.email}`);       
        array[8] = adicionadorDeClasse(email, docViewTop, docViewBottom);
    }


    return array.every((item)=> item);
}

function adicionadorDeClasse(item, docViewTop, docViewBottom) {
    if(item.offsetTop >= docViewTop && docViewBottom >= (item.clientHeight + item.offsetTop)){
        item.classList.add(`${style.animation_vertical}`);
        return true;
    }

    return false;
    
}

export default Portifolio;